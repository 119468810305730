export const CREATE = "create";
export const UPDATE = "update";
export const DELETE = "delete";
export const MODE = "mode";
export const BTN_CLICK = "btn_click";
export const EVENT_SELECTED = "event_selected";
export const BEFORE_DRAG = "before_drag";
export const AFTER_DRAG = "after_drag";
export const BEFORE_CHANGE = "before_change";
export const BEFORE_LBOX = "before_lbox";
export const ON_CLICK_EVENT = "on_click_event";

export const eventsList = [
  { type: "onEventChanged", action: UPDATE },
  { type: "onEventDeleted", action: DELETE },
  { type: "onViewChange", action: MODE },
  { type: "onEventSelected", action: EVENT_SELECTED },
];

export const UNIT = "unit";
export const MONTH = "month";
export const WEEK = "week";
export const MOVE = "move";
export const DAY = "day";
export const RESIZE = "resize";
export const NEW_EVENT = "new-size";
export const EMPLOYEES = "employees";
export const EQUIPMENT = "equipment";
export const DEFAULT_KEY = "-1";
export const ROUND_TIME_MIN = 15;

export const schedulerFilter = {
  ALL: "all",
  IS_WORKING: "is_working",
  IS_AVAILABLE: "is_available",
};

export const schedulerViewOpt = [
  { value: UNIT, label: DAY },
  { value: WEEK, label: WEEK },
  { value: MONTH, label: MONTH },
];

export const schedulerFilterDefault = {
  ALL: "All",
  IS_WORKING: "Is working",
  IS_AVAILABLE: "Is available",
};
