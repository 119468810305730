export const SET_CONTACTS = "SET_CONTACTS";
export const GET_CONTACTS = "GET_CONTACTS";
export const SHOW_MESSAGE = "SHOW_MESSAGE";
export const SEARCH_CONTACTS = "SEARCH_CONTACTS";
export const DELETE_CONTACTS = "DELETE_CONTACTS";
export const IMPORT_CONTACTS = "IMPORT_CONTACTS";
export const IMPORT_CONTACTS_RESULT = "IMPORT_CONTACTS_RESULT";
export const IMPORT_STARTED = "IMPORT_STARTED";
export const SET_IMPORT_STATUS_OK = "SET_IMPORT_STATUS_OK";
export const CHANGE_IMAGE = "CHANGE_IMAGE";
export const CHANGE_IMAGE_SUCCESS = "CHANGE_IMAGE_SUCCESS";
export const GET_SINGLE_CONTACTS = "GET_SINGLE_CONTACTS";
export const SET_SINGLE_CONTACTS = "SET_SINGLE_CONTACTS";
export const EDIT_SINGLE_CONTACTS = "EDIT_SINGLE_CONTACTS";
export const REMOVE_FROM_FUNNEL = "REMOVE_FROM_FUNNEL";
export const STORE_CONTACTS = "STORE_CONTACTS";
export const UPDATE_CONTACTS = "UPDATE_CONTACTS";
export const GET_TAGS = "GET_TAGS";
export const SET_TAGS = "SET_TAGS";
export const SET_CONTACT_TO_FUNNEL = "SET_CONTACT_TO_FUNNEL";
export const SET_UPDATE_SUCCESS_INDICATOR = "SET_UPDATE_SUCCESS_INDICATOR";
export const SET_LOADING_CONTACTS = "SET_LOADING_CONTACTS";
export const UPDATE_CONTACT_FUNNEL = "UPDATE_CONTACT_FUNNEL";
export const SET_CONTACTS_ORDER = "SET_CONTACTS_ORDER";
export const UNSUBSCRIBE_CONTACT = "UNSUBSCRIBE_CONTACT";
export const REMOVE_CONTACTS_FROM_CONVERSATION =
  "REMOVE_CONTACTS_FROM_CONVERSATION";
export const UPDATE_LOYALTY_CONTACT_INFO = "UPDATE_LOYALTY_CONTACT_INFO";
export const CONTACT_PARTIAL_UPDATE = "CONTACT_PARTIAL_UPDATE";
export const ADD_POINTS_TO_CONTACT = "ADD_POINTS_TO_CONTACT";
export const ADD_CONTACTS_TO_CAMPAIGN = "ADD_CONTACTS_TO_CAMPAIGN";
export const FILTER_CONTACTS = "FILTER_CONTACTS";
export const CREATE_INSURANCE = "CREATE_INSURANCE";
export const UPDATE_INSURANCE = "UPDATE_INSURANCE";
export const DELETE_INSURANCE = "DELETE_INSURANCE";
export const SET_INSURANCE = "SET_INSURANCE";
export const REMOVE_INSURANCE = "REMOVE_INSURANCE";
export const EDIT_INSURANCE = "EDIT_INSURANCE";
export const CREATE_CONTACT_ADDRESS = "CREATE_CONTACT_ADDRESS";
export const UPDATE_CONTACT_ADDRESS = "UPDATE_CONTACT_ADDRESS";
export const UPDATE_CONTACT_CARD = "UPDATE_CONTACT_CARD";
export const EXTERNAL_PLATFORM = "EXTERNAL_PLATFORM";
export const DOWNLOAD_CONTACTS = "DOWNLOAD_CONTACTS";
export const ADD_TAGS_IN_BULK = "ADD_TAGS_IN_BULK";
export const REMOVE_TAGS_IN_BULK = "REMOVE_TAGS_IN_BULK";
export const SET_CONTACTS_FOR_TAGS_BULK = "SET_CONTACTS_FOR_TAGS_BULK";
export const UPDATE_CONTACT_STATUS = "UPDATE_CONTACT_STATUS";
export const REMOVE_FUNNEL_FROM_CONTACT = "REMOVE_FUNNEL_FROM_CONTACT";
export const GET_CONTACTS_FOR_MERGING = "GET_CONTACTS_FOR_MERGING";
export const SET_CONTACTS_FOR_MERGING = "SET_CONTACTS_FOR_MERGING";
export const MERGE_CONTACTS = "MERGE_CONTACTS";
export const REMOVE_MERGED_CONTACT = "REMOVE_MERGED_CONTACT";
export const UPDATE_MERGED_CONTACTS = "UPDATE_MERGED_CONTACTS";
export const SET_SINGLE_CONTACT_LOADING = "SET_SINGLE_CONTACT_LOADING";
