export const NEW_APPOINTMENT = "NEW_APPOINTMENT";
export const NEW_APPOINTMENT_SUCCESS = "NEW_APPOINTMENT_SUCCESS";
export const GET_APPOINTMENTS = "GET_APPOINTMENTS";
export const GET_APPOINTMENTS_SUCCESS = "GET_APPOINTMENTS_SUCCESS";
export const SHOW_MESSAGE = "SHOW_MESSAGE";
export const DELETE_APPOINTMENT = "DELETE_APPOINTMENT";
export const DELETE_APPOINTMENT_SUCCESS = "DELETE_APPOINTMENT_SUCCESS";
export const EDIT_APPOINTMENT = "EDIT_APPOINTMENT";
export const EDIT_APPOINTMENT_SUCCESS = "EDIT_APPOINTMENT_SUCCESS";
export const GET_CALENDAR = "GET_CALENDAR";
export const GET_CALENDAR_SUCCESS = "GET_CALENDAR_SUCCESS";
export const GET_PREVIOUS_AND_NEXT_APPOITMENT =
  "GET_PREVIOUS_AND_NEXT_APPOITMENT";
export const PREVIOUS_AND_NEXT_SUCCESS = "PREVIOUS_AND_NEXT_SUCCESS";
export const CHECK_OR_CREATE_NEW_APPOINTMENT =
  "CHECK_OR_CREATE_NEW_APPOINTMENT";
export const CHECK_OR_EDIT_APPOINTMENT = "CHECK_OR_EDIT_APPOINTMENT";
