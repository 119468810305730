// @ts-nocheck
/* eslint-disable array-callback-return */
/* eslint-disable default-case */
import {
  SET_HISTORY_ACTIVITY,
  UPDATE_HISTORY_ACTIVITY,
  PUSH_NEW_STATUS_CHANGE,
  SET_NEW_ACTIVITY,
  PUSH_NEW_AMOUNT_SPENT,
  GET_HISTORY_ACTIVITY,
} from "../constants/Activity";
import produce from "immer";
import { createStatusChanged } from "./../../helpers/createNewFunnel";
import { createAmountSpentActivity } from "helpers/createAmountSpentActivity";
import { updateActivityList } from "helpers/updateActivityList";

export const initialState = {
  historyActivities: { isLoading: true },
};

const historyActivityReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    const { type, payload } = action;
    switch (type) {
      case GET_HISTORY_ACTIVITY:
        draft.historyActivities[payload.id] = {
          ...draft.historyActivities[payload.id],
          isLoading: true,
        };
        break;
      case SET_HISTORY_ACTIVITY:
        draft.historyActivities[payload.id] = {
          count: payload.data.count,
          page: payload.data.current,
          current: payload.currentPage,
          next: payload.data.next,
          previous: payload.data.previous,
          total_pages: payload.data.total_pages,
          results:
            payload.currentPage === 1
              ? payload.data.results
              : [
                  ...draft.historyActivities[payload.id].results,
                  ...payload.data.results,
                ],
          isLoading: false,
        };
        break;
      case UPDATE_HISTORY_ACTIVITY:
        if (payload.data.contact.id in draft.historyActivities) {
          const allActivityHistory =
            draft.historyActivities[payload.data.contact.id];
          updateActivityList(allActivityHistory, payload);
        }
        break;
      case PUSH_NEW_STATUS_CHANGE:
        const newActivity = createStatusChanged(payload);
        if (newActivity) {
          if (newActivity.contact.id in draft.historyActivities) {
            draft.historyActivities[newActivity.contact.id].results.unshift(
              newActivity
            );
          }
        }
        break;
      case PUSH_NEW_AMOUNT_SPENT:
        const amountSpentActivity = createAmountSpentActivity(payload);
        if (amountSpentActivity.contact.id in draft.historyActivities) {
          draft.historyActivities[
            amountSpentActivity.contact.id
          ].results.unshift(amountSpentActivity);
        }
        break;
      case SET_NEW_ACTIVITY:
        if (payload.id in draft.historyActivities) {
          const activity = draft.historyActivities[payload.id].results.find(
            (activity) => activity.id == payload.data.id
          );

          if (!activity) {
            draft.historyActivities[payload.id].results.unshift(payload.data);
          }
        }
    }
  });

export default historyActivityReducer;
