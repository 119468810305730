import React from "react";
import { Link, useLocation } from "react-router-dom";
import { Menu, Grid } from "antd";
import IntlMessage from "../util-components/IntlMessage";
import Icon from "../util-components/Icon";
import { connect, useSelector } from "react-redux";
import { SIDE_NAV_LIGHT, NAV_TYPE_SIDE } from "constants/ThemeConstant";
import utils from "utils";
import { onMobileNavToggle } from "redux/actions/Theme";
import { makeSelectCurrentUser } from "redux/selectors/Users";
import { APP_PREFIX_PATH } from "configs/AppConfig";
import { SettingOutlined } from "@ant-design/icons";
import translations from "configs/translations";
import AppLocale from "lang/index";
import { ROLE_MANDATORY_ORGANIZATION } from "constants/RoleConstants";

const chosenLang = AppLocale.en.locale;

const { SubMenu } = Menu;
const { useBreakpoint } = Grid;

const setLocale = (isLocaleOn, localeKey) =>
  isLocaleOn ? <IntlMessage id={localeKey} /> : localeKey.toString();

const setDefaultOpen = (key) => {
  let keyList = [];
  let keyString = "";
  if (key) {
    const arr = key.split("-");
    for (let index = 0; index < arr.length; index++) {
      const elm = arr[index];
      index === 0 ? (keyString = elm) : (keyString = `${keyString}-${elm}`);
      keyList.push(keyString);
    }
  }
  return keyList;
};

const SideNavContent = (props) => {
  const {
    sideNavTheme,
    routeInfo,
    hideGroupTitle,
    localization,
    onMobileNavToggle,
    navigationConfig,
  } = props;
  const isMobile = !utils.getBreakPoint(useBreakpoint()).includes("lg");
  const closeMobileNav = () => {
    if (isMobile) {
      onMobileNavToggle(false);
    }
  };

  // Manualy set selected menu item in the side-nav based on the path.
  const pathSegments = useLocation().pathname.split("/");
  const selectedMenuItem = pathSegments[2];
  const user = useSelector(makeSelectCurrentUser());

  let navigationConfigForUser = [];

  navigationConfig.forEach((element) => {
    user?.app_access_list?.includes(element?.key) &&
      navigationConfigForUser.push(element);
  });

  const hasOrganization =
    !ROLE_MANDATORY_ORGANIZATION.includes(user?.role?.access_type) ||
    user?.organization;

  const hasPayments =
    user?.is_premium_user ||
    (user?.active_subscription &&
      user?.payment_information?.payment_method?.is_valid);

  const navigationConfigMenu =
    hasOrganization && hasPayments
      ? navigationConfigForUser
      : [
          {
            key: "settings",
            path: `${APP_PREFIX_PATH}/settings`,
            title: translations[7][chosenLang],
            breadcrumb: false,
            submenu: [],
            icon: SettingOutlined,
          },
        ];

  return (
    <div>
      <Menu
        theme={sideNavTheme === SIDE_NAV_LIGHT ? "light" : "dark"}
        mode="inline"
        style={{ height: "100%", borderRight: 0 }}
        defaultSelectedKeys={[routeInfo?.key]}
        defaultOpenKeys={setDefaultOpen(routeInfo?.key)}
        className={`${hideGroupTitle ? "hide-group-title" : ""} aeva-sidebar`}
        selectedKeys={[selectedMenuItem]}
      >
        {navigationConfigMenu.map((menu) =>
          menu.submenu.length > 0 ? (
            <SubMenu
              key={menu.key}
              title={
                <span>
                  <Icon type={menu?.icon} />
                  <span>{setLocale(localization, menu.title)}</span>
                </span>
              }
            >
              {menu.submenu.map((subMenuSecond) => (
                <Menu.Item key={subMenuSecond.key}>
                  <span>{setLocale(localization, subMenuSecond.title)}</span>
                  {subMenuSecond.icon ? (
                    <Icon type={subMenuSecond?.icon} />
                  ) : null}
                  <Link
                    onClick={() => closeMobileNav()}
                    to={subMenuSecond.path}
                    disabled={
                      selectedMenuItem == "employee-calendar" &&
                      subMenuSecond.key == selectedMenuItem
                    }
                  />
                </Menu.Item>
              ))}
            </SubMenu>
          ) : (
            <Menu.Item key={menu.key}>
              {menu.icon ? <Icon type={menu?.icon} /> : null}
              <span>{setLocale(localization, menu?.title)}</span>
              {menu.path ? (
                <Link
                  onClick={() => closeMobileNav()}
                  to={menu.path}
                  disabled={
                    selectedMenuItem === "scheduler" &&
                    menu.key === selectedMenuItem
                  }
                />
              ) : null}
            </Menu.Item>
          )
        )}
      </Menu>
    </div>
  );
};

const TopNavContent = (props) => {
  const { topNavColor, localization, navigationConfig } = props;
  return (
    <Menu mode="horizontal" style={{ backgroundColor: topNavColor }}>
      {navigationConfig.map((menu) =>
        menu.submenu.length > 0 ? (
          <SubMenu
            key={menu.key}
            popupClassName="top-nav-menu aeva-sidebar"
            title={
              <span>
                {menu.icon ? <Icon type={menu?.icon} /> : null}
                <span>{setLocale(localization, menu.title)}</span>
              </span>
            }
          >
            {menu.submenu.map((subMenuFirst) =>
              subMenuFirst.submenu.length > 0 ? (
                <SubMenu
                  key={subMenuFirst.key}
                  icon={
                    subMenuFirst.icon ? (
                      <Icon type={subMenuFirst?.icon} />
                    ) : null
                  }
                  title={setLocale(localization, subMenuFirst.title)}
                >
                  {subMenuFirst.submenu.map((subMenuSecond) => (
                    <Menu.Item key={subMenuSecond.key}>
                      <span>
                        {setLocale(localization, subMenuSecond.title)}
                      </span>
                      <Link to={subMenuSecond.path} />
                    </Menu.Item>
                  ))}
                </SubMenu>
              ) : (
                <Menu.Item key={subMenuFirst.key}>
                  {subMenuFirst.icon ? (
                    <Icon type={subMenuFirst?.icon} />
                  ) : null}
                  <span>{setLocale(localization, subMenuFirst.title)}</span>
                  <Link to={subMenuFirst.path} />
                </Menu.Item>
              )
            )}
          </SubMenu>
        ) : (
          <Menu.Item key={menu.key}>
            {menu.icon ? <Icon type={menu?.icon} /> : null}
            <span>{setLocale(localization, menu?.title)}</span>
            {menu.path ? <Link to={menu.path} /> : null}
          </Menu.Item>
        )
      )}
    </Menu>
  );
};

const MenuContent = (props) => {
  return props.type === NAV_TYPE_SIDE ? (
    <SideNavContent {...props} />
  ) : (
    <TopNavContent {...props} />
  );
};

const mapStateToProps = ({ theme }) => {
  const { sideNavTheme, topNavColor } = theme;
  return { sideNavTheme, topNavColor };
};

export default connect(mapStateToProps, { onMobileNavToggle })(MenuContent);
