/* eslint-disable default-case */
import produce from "immer";
import { STORE_CONTACT_SETTINGS } from "redux/constants/ContactSettings";
import {
  SET_CONTACTS,
  SHOW_MESSAGE,
  SET_SINGLE_CONTACTS,
  CHANGE_IMAGE_SUCCESS,
  UPDATE_CONTACTS,
  SET_TAGS,
  SET_UPDATE_SUCCESS_INDICATOR,
  SET_LOADING_CONTACTS,
  UPDATE_CONTACT_FUNNEL,
  SET_CONTACTS_ORDER,
  UPDATE_LOYALTY_CONTACT_INFO,
  ADD_POINTS_TO_CONTACT,
  SET_INSURANCE,
  REMOVE_INSURANCE,
  EDIT_INSURANCE,
  SET_CONTACTS_FOR_TAGS_BULK,
  SET_CONTACTS_FOR_MERGING,
  REMOVE_MERGED_CONTACT,
  UPDATE_MERGED_CONTACTS,
  SET_IMPORT_STATUS_OK,
  IMPORT_STARTED,
  SET_SINGLE_CONTACT_LOADING,
} from "../constants/Contact";
import { isEmpty } from "lodash";

export const initialState = {
  contacts: {
    count: 0,
    current: 1,
    next: null,
    previous: null,
    total_pages: 0,
    results: [],
    isLoading: true,
    order: "descend",
    orderField: "last_interaction",
  },
  contactsForBulkTag: [],
  message: "",
  contact: { isLoading: true },
  tags: [],
  updateSuccess: false,
  allContacts: [],
  modalIsVisible: false,
  importStatusOK: true,
  importStarted: false,
};

const contactReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    const { type, payload } = action;
    switch (type) {
      case SET_CONTACTS:
        draft.contacts = {
          count: payload.data.count,
          current: payload.data.current,
          next: payload.data.next,
          previous: payload.data.previous,
          total_pages: payload.data.total_pages,
          results: payload.data.results,
          isLoading: false,
          order: payload.order,
          orderField: payload.orderField,
        };
        break;
      case SHOW_MESSAGE:
        draft.message = payload;
        break;
      case SET_UPDATE_SUCCESS_INDICATOR:
        draft.updateSuccess = false;
        break;
      case SET_SINGLE_CONTACT_LOADING:
        draft.contact.isLoading = true;
        break;
      case SET_SINGLE_CONTACTS:
        draft.contact = {
          ...payload.data,
          isLoading: false,
        };
        break;
      case CHANGE_IMAGE_SUCCESS:
        draft.contact = payload;
        break;
      case STORE_CONTACT_SETTINGS:
        draft.updateSuccess = false;
        break;
      case UPDATE_CONTACTS:
        const constacts = { ...draft.contacts };
        constacts.results.push(payload);
        draft.contacts = constacts;
        draft.updateSuccess = true;
        break;
      case SET_TAGS:
        draft.tags = payload;
        break;
      case SET_LOADING_CONTACTS:
        draft.contacts.isLoading = payload;
        break;
      case UPDATE_CONTACT_FUNNEL:
        if (draft.contact !== {}) {
          if (draft.contact["status"] !== null) {
            draft.contact["status"]?.map((item) => {
              if (item?.id === payload) {
                item = null;
              }
              return item;
            });
          }
        }
        break;
      case SET_CONTACTS_ORDER:
        draft.contacts.order = payload.order;
        draft.contacts.orderField = payload.orderField;
        break;
      case UPDATE_LOYALTY_CONTACT_INFO:
        draft.contact.is_loyalty_user = true;
        break;
      case ADD_POINTS_TO_CONTACT:
        const tempCurrentContact = { ...draft.contact };
        const contact_set = payload?.users?.contact_set;

        if (contact_set?.length && !isEmpty(tempCurrentContact)) {
          const { amount_spent, status } = contact_set[0];
          tempCurrentContact.status = status;
          tempCurrentContact.amount_spent = amount_spent;
        }
        draft.contact = tempCurrentContact;
        break;
      case SET_INSURANCE:
        const contactCopy = { ...draft.contact };
        const insurnaces = contactCopy?.contactcard_set?.[0]?.insurances;
        const res = insurnaces.find((insurance) => {
          return (
            insurance?.policy == payload?.policy &&
            insurance?.provider == payload?.provider &&
            insurance?.provider_number == payload?.provider_number
          );
        });
        if (!res) {
          insurnaces.push(payload);
        } else {
          res.id = payload?.id;
        }
        draft.contact = contactCopy;
        break;
      case REMOVE_INSURANCE:
        const insuranceCopy = [
          ...draft.contact.contactcard_set?.[0]?.insurances,
        ];
        const filteredInsurance = insuranceCopy.filter(
          (insurance) => insurance?.id != payload
        );
        draft.contact.contactcard_set[0].insurances = filteredInsurance;
        break;
      case EDIT_INSURANCE:
        const insurances = draft.contact.contactcard_set?.[0]?.insurances;
        const insuranceInd = insurances.findIndex(
          (insurance) => insurance?.id == payload?.id
        );
        const listCopy = [...insurances];
        listCopy[insuranceInd] = payload;
        draft.contact.contactcard_set[0].insurances = listCopy;
        break;
      case SET_CONTACTS_FOR_TAGS_BULK:
        const contacts = payload?.map((contact) => ({
          id: contact?.id,
          avatar: contact?.profile_picture?.avatar || null,
          name: `${contact?.name} ${contact?.last_name}`,
        }));
        draft.contactsForBulkTag = contacts;
        break;

      case SET_CONTACTS_FOR_MERGING:
        draft.constactsForMerging = payload;
        break;

      case REMOVE_MERGED_CONTACT:
        let indexDelete = draft.contacts.results.findIndex(
          (el) => el.id === payload
        );
        draft.contacts.results = draft.contacts.results
          .slice(0, indexDelete)
          .concat(
            draft.contacts.results.slice(
              indexDelete + 1,
              draft.contacts.results.length
            )
          );
        break;
      case UPDATE_MERGED_CONTACTS:
        let indexMergedContact = draft.contacts.results.findIndex(
          (el) => el.id === payload.id
        );
        draft.contacts.results[indexMergedContact] = payload;
        break;
      case IMPORT_STARTED:
        draft.importStarted = payload;
        break;
      case SET_IMPORT_STATUS_OK:
        draft.importStatusOK = payload;
        break;
    }
  });

export default contactReducer;
